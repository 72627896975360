import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import CommonModal from "./CommonModal";
import Cancel from "../buttons/Cancel";
import Reset from "./../buttons/Reset";
import Submit from "./../buttons/Submit";
import { useSelector } from "react-redux";
import { Spinner } from "@material-tailwind/react";
import Utility from "../../Utils/Utility";
import { HttpClient } from "../../Redux/controllers/HttpClient";
import { SlReload } from "react-icons/sl";
import { useModal } from "../../context/modalContext";
import Multiselect from "multiselect-react-dropdown";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Category Name is required").trim(),
  drugCodes: Yup.array().min(1, "At least one drug must be selected"),
});

const DrugCategory = ({ onClose, edit }) => {
  const { userID } = useModal();
  const { token } = useSelector((state) => state.user);
  const [showSpin, setShowSpin] = useState(false);
  const [drugData, setDrugData] = useState([]);
  const [drugCode, setDrugCode] = useState(
    edit ? userID?.drug_code?.map((drug) => drug._id) : []
  );

  useEffect(() => {
    if (edit && userID?.drug_code) {
      setDrugCode(userID.drug_code.map((drug) => drug._id));
    }
    return () => {
      // Cleanup on unmount
      setDrugCode([]);
      setDrugData([]);
    };
  }, [edit, userID]);

  const formik = useFormik({
    initialValues: {
      name: edit ? userID?.name : "",
      drugCodes: edit ? drugCode : [],
    },
    validationSchema: validationSchema,
    onSubmit: edit ? handleEditSubmit : handleSubmit,
    enableReinitialize: true,
  });

  async function handleSubmit(values) {
    if (drugCode.length === 0) {
      Utility.eToast("Please select at least one drug");
      return;
    }

    setShowSpin(true);
    const userData = {
      name: values.name.trim(),
      drug_code: drugCode,
    };

    try {
      await HttpClient.apiCaller(
        "admin/category",
        "POST",
        userData,
        token,
        null
      );
      Utility.sToast("Category added successfully");
      handleClose();
    } catch (err) {
      console.error("Error adding category:", err);
      Utility.eToast(err?.response?.data?.err || "Failed to add category");
    } finally {
      setShowSpin(false);
    }
  }

  async function handleEditSubmit(values) {
    if (drugCode.length === 0) {
      Utility.eToast("Please select at least one drug");
      return;
    }

    setShowSpin(true);
    const userData = {
      _id: userID._id,
      name: values.name.trim(),
      drug_code: drugCode,
    };

    try {
      await HttpClient.apiCaller(
        "admin/category-edit",
        "PUT",
        userData,
        token,
        null
      );
      Utility.sToast("Category updated successfully");
      handleClose();
    } catch (err) {
      console.error("Error updating category:", err);
      Utility.eToast(err?.response?.data?.err || "Failed to update category");
    } finally {
      setShowSpin(false);
    }
  }

  const handleClose = () => {
    formik.resetForm();
    setDrugCode([]);
    onClose();
  };

  const handleReset = () => {
    formik.resetForm();
    setDrugCode([]);
  };

  useEffect(() => {
    getCodes();
  }, [token]);

  const getCodes = async () => {
    try {
      const res = await HttpClient.apiCaller(
        "admin/get-drug-code",
        "GET",
        null,
        token,
        null
      );
      setDrugData(res?.data?.code || []);
    } catch (err) {
      console.error("Error fetching drug codes:", err);
      Utility.eToast("Failed to fetch drug codes");
    }
  };

  const newDrugData = drugData?.map((item) => ({
    drugCode: item?.name,
    id: item?._id,
  }));

  const handleDrugCode = (selectedOption) => {
    const selectedIds = selectedOption.map((item) => item?.id);
    setDrugCode(selectedIds);
    formik.setFieldValue("drugCodes", selectedIds);
  };

  const alreadySelectedDrugData = userID?.drug_code?.map((item) => ({
    drugCode: item?.name,
    id: item?._id,
  }));

  return (
    <CommonModal
      heading={edit ? "Edit Drug Category" : "Add Drug Category"}
      onClose={handleClose}
    >
      <form onSubmit={formik.handleSubmit} className="space-y-4">
        <div className="mt-9">
          <p className="mb-2 poppins-medium">Category name</p>
          <input
            type="text"
            placeholder="Enter category name"
            className="w-full h-10 mb-2 rounded poppins-regular bg-[#ECF1F6] p-2"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            name="name"
            value={formik.values.name}
          />
          {formik.errors.name && formik.touched.name && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.name}
            </div>
          )}

          <p className="mb-2 poppins-medium">Select drug</p>
          <Multiselect
            options={newDrugData}
            displayValue="drugCode"
            placeholder="Select Drug"
            onSelect={handleDrugCode}
            onRemove={handleDrugCode}
            selectedValues={edit ? alreadySelectedDrugData : []}
          />
          {formik.errors.drugCodes && formik.touched.drugCodes && (
            <div className="text-red-500 poppins-regular ml-1 text-sm">
              {formik.errors.drugCodes}
            </div>
          )}
        </div>

        <div className="flex justify-between mt-3">
          <div>
            {!edit && (
              <button
                type="button"
                onClick={handleReset}
                className="flex items-center px-2 py-1 poppins-medium border-2 border-gray-400 rounded hover:opacity-80 bg-[#ECF1F6]"
              >
                <SlReload className="mr-1" />
                Reset
              </button>
            )}
          </div>
          <div className="flex gap-4 ml-auto">
            <Cancel onClick={handleClose} />
            <Submit
              label={
                showSpin ? (
                  <Spinner color="white" />
                ) : edit ? (
                  "Save Changes"
                ) : (
                  "Submit"
                )
              }
            />
          </div>
        </div>
      </form>
    </CommonModal>
  );
};

export default DrugCategory;
