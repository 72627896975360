import React from "react";
import Dashboard from "../../components/Dashboard";
import { FaPersonWalking, FaWheelchair } from "react-icons/fa6";
import { IoBagAddSharp } from "react-icons/io5";
import { Spinner } from "@material-tailwind/react";
import { FaUserAlt } from "react-icons/fa";
import useSpecifiUsersHook from "../../components/UseSpecificUserHook";
import { CardWithIcon } from "../../components/Card";
import { useNavigate } from "react-router-dom";

const PhysicDashboard = () => {
  const {
    mro,
    patient,
    collectionSite,
    collector,
    technician,
    organization,
    spin,
    clinic,
    testt,
    setSpin,
    myAssociates,
    myAssociatesType,
  } = useSpecifiUsersHook();

  // Create base cards data
  const baseCardsData = [
    {
      label: "Active Clinic",
      icon: <FaUserAlt />,
      count: clinic,
      path: "/physic-clinic",
    },
    {
      label: "Active Organization",
      icon: <FaUserAlt />,
      count: organization,
      path: "/physic-organization",
    },
    {
      label: "Active Patient",
      icon: <FaWheelchair />,
      count: patient,
      path: "/physician-patient",
    },
    {
      label: "Forensic Test Order",
      icon: <IoBagAddSharp />,
      count: testt,
      path: "/mroftop",
    },
  ];

  // Add myAssociates card conditionally
  const cardsData =
    myAssociates.length !== 0
      ? [
          ...baseCardsData.slice(0, 2), // Insert after the first two cards
          {
            label: `My Associated ${
              myAssociatesType === "organization" ? "Organization" : "Clinic"
            }`,
            icon: <FaUserAlt />,
            count: myAssociates,
            path:
              myAssociatesType === "organization"
                ? "/physic-organization"
                : "/physic-clinic",
          },
          ...baseCardsData.slice(2), // Add remaining cards
        ]
      : baseCardsData;

  const navigate = useNavigate();

  return (
    <Dashboard>
      {spin ? (
        <div className="w-full mt-20 flex items-center justify-center">
          <Spinner className="h-12 w-12" />{" "}
        </div>
      ) : (
        <div className="grid xs:grid-cols-2 sm:grid-cols-3 w-full gap-2 cursor-pointer">
          {cardsData?.map((item, index) => (
            <CardWithIcon
              key={index}
              item={item}
              onClick={() => navigate(item?.path)}
            />
          ))}
        </div>
      )}
    </Dashboard>
  );
};

export default PhysicDashboard;
